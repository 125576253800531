import { GID } from '@unlikelystudio/commerce-connector'

const GID_PREFIX = 'gid://shopify'

type Entities = 'Product' | 'ProductVariant'

export const getReferenceIdFromBase64EncodedId = (
  encodedId: string,
): number => {
  const gid = Buffer.from(encodedId, 'base64').toString()
  const splitGid: Array<string> = gid.split('/')
  const id = splitGid[splitGid.length - 1].split('?')[0]
  return Number(id)
}

export const getBase64fromProductId = (id: number) => {
  const gid = Buffer.from(`${GID_PREFIX}/Product/${id}`, 'utf-8').toString(
    'base64',
  )

  return gid
}

export function getBase64fromGID(gid: string) {
  if (!gid) return null
  return Buffer.from(gid, 'utf-8').toString('base64')
}

export function getGIDFromBase64(id: string, property: string) {
  if (id?.startsWith(GID_PREFIX)) return id

  try {
    const reference = getReferenceIdFromBase64EncodedId(id)
    return `${GID_PREFIX}/${property}/${reference}`
  } catch (error) {
    return id
  }
}

export function addGIDPrefix(entity: Entities, id: string | number) {
  return `${GID_PREFIX}/${entity}/${id}` as GID
}

export function removeGIDPrefix(entity: Entities, id: string) {
  return id?.replace(`${GID_PREFIX}/${entity}/`, '')
}
