import { gql } from 'graphql-request'
import { DEFAULT_LOCALE } from '~/lib/constants'
import { getMultistoreCredentials } from '~/lib/get-multistore-token'

import { getShopifyStorefrontClient } from '@unlikelystudio/react-ecommerce-hooks'

const productByHandle = gql`
  query productByHandle($id: ID!, $withInventory: Boolean!) {
    node(id: $id) {
      ... on Product {
        availableForSale
        title
        id
        handle
        collections(first: 3) {
          edges {
            node {
              title
            }
          }
        }
        variants(first: 50) {
          edges {
            node {
              id
              title
              sku
              availableForSale
              quantityAvailable @include(if: $withInventory)
              priceV2 {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
              priceV2 {
                amount
                currencyCode
              }
            }
          }
        }
        compareAtPriceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

type GetProductByIdParams = {
  id: string
  withInventory?: boolean
}

export async function getProductById({
  id,
  withInventory = false,
}: GetProductByIdParams) {
  try {
    const config = getMultistoreCredentials(DEFAULT_LOCALE)

    const store = {
      storeUrl: config?.url,
      storefrontAccessToken: config?.storeFrontAccessToken,
    }

    const client = getShopifyStorefrontClient(store)

    const node = await client.request(productByHandle, {
      id,
      withInventory,
    })

    if (!node) return null

    return { data: node } as any
  } catch (error) {
    return null
  }
}
