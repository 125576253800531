/**
 * It takes a string and returns an object with a key of aria-label and a value of the string.
 * @param {string} str - string - The string to be converted to an object.
 * @returns {
 *   'aria-label': str as string,
 * }
 */
export default function getAriaLabelProp(str: string) {
  if (typeof str !== 'string' || !str) return {}
  return {
    'aria-label': str as string,
  }
}
