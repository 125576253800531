import WishlistManager, {
  WishlistManagerProps,
} from '~/components/WishlistManager'
import { HeartIcon } from '~/components/icons'

import { getBase64fromGID } from '~/utils/shopify-ids'

interface ProductWishlistProps extends Omit<WishlistManagerProps, 'children'> {
  className?: string
}

function ProductWishlist({
  className,
  id,
  ids,
  shopifyId,
  shopifyIds,
  trackingData,
}: ProductWishlistProps) {
  return (
    <WishlistManager
      id={id}
      ids={ids}
      shopifyId={getBase64fromGID(shopifyId)}
      shopifyIds={shopifyIds?.map(getBase64fromGID)}
      trackingData={trackingData}>
      {({ active, handleClick }) => (
        <HeartIcon
          className={className}
          light={true}
          active={active}
          onClick={handleClick}
        />
      )}
    </WishlistManager>
  )
}

ProductWishlist.defaultProps = {}

export default ProductWishlist
